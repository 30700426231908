import React from 'react';
import { graphql, PageProps } from 'gatsby';
import SEO from '~/components/seo';
import Layout from '~/components/layout';
import TitleWithCatchCopy from '~/components/TitleWithCatchCopy';
import { TopSection, TopTitleWrapper } from '~/styles';

const NotFound: React.VFC<PageProps> = () => (
  <>
    <SEO title="404" />
    <Layout>
      <TopSection>
        <TopTitleWrapper>
          <TitleWithCatchCopy
            title="404.code"
            catchCopy="404.message"
            byKey
            isIntersection
          />
        </TopTitleWrapper>
      </TopSection>
    </Layout>
  </>
);

export default NotFound;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
