import React from 'react';
import styled, { css } from 'styled-components';
import { Trans } from 'gatsby-plugin-react-i18next';
import { media, commonKeyframes, easings } from '~/styles';
import TextBreaks from '~/components/TextBreaks';

interface TitleWithCatchCopyProps {
  title?: string;
  catchCopy: string;
  catchSub?: string;
  className?: string;
  byKey?: boolean;
  top?: boolean;
  isIntersection: boolean;
}

const TitleWithCatchCopy = React.forwardRef<
  HTMLDivElement,
  TitleWithCatchCopyProps
>(
  (
    { title, catchCopy, catchSub, className, byKey, top, isIntersection },
    ref,
  ) => (
    <Wrapper className={className} ref={ref} isTop={top}>
      {title && (
        <Title isIntersection={isIntersection}>
          {byKey ? (
            <Trans i18nKey={title} components={TextBreaks} />
          ) : (
            `${title}`
          )}
        </Title>
      )}
      <CatchWrapper isTop={top}>
        <CatchCopyWrapper>
          <CatchCopy
            isIntersection={isIntersection}
            isTop={top}
            style={
              {
                '--delay': `${top ? 0.6 : 0.15}s`,
              } as React.CSSProperties
            }
          >
            {byKey ? (
              <Trans i18nKey={catchCopy} components={TextBreaks} />
            ) : (
              `${catchCopy}`
            )}
          </CatchCopy>
        </CatchCopyWrapper>
        {catchSub && (
          <CatchSub
            isIntersection={isIntersection}
            isTop={top}
            style={
              {
                '--delay': `${top ? 1.2 : 0.6}s`,
              } as React.CSSProperties
            }
          >
            {byKey ? (
              <Trans i18nKey={catchSub} components={TextBreaks} />
            ) : (
              `${catchSub}`
            )}
          </CatchSub>
        )}
      </CatchWrapper>
    </Wrapper>
  ),
);

const Wrapper = styled.div<{ isTop?: boolean }>`
  display: grid;
  grid-gap: 80px;
  justify-items: center;
  text-align: center;
  max-width: 1024px;
  margin: 0 auto;
  z-index: 1;

  ${media.mdDown} {
    grid-gap: 60px;
    width: 100%;
  }

  ${({ isTop }) =>
    isTop &&
    css`
      ${media.mdDown} {
        justify-items: start;
      }
    `}
`;

const Title = styled.h1<{ isIntersection: boolean }>`
  font-weight: normal;
  font-size: 24px;
  letter-spacing: 0.2em;
  white-space: pre-wrap;
  margin: 0;
  opacity: 0;
  --delay: 0s;

  ${media.smDown} {
    font-size: 20px;
  }

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      animation: ${commonKeyframes.fadeIn} 0.6s ${easings.easeOutCubic}
        var(--delay) forwards;
    `}
`;

const CatchWrapper = styled.div<{ isTop?: boolean }>`
  display: grid;
  grid-gap: 40px;
  justify-items: center;
  text-align: center;

  ${media.mdDown} {
    grid-gap: 30px;
  }

  ${({ isTop }) =>
    isTop &&
    css`
      ${media.mdDown} {
        justify-items: start;
      }
    `}
`;

const CatchCopyWrapper = styled.div`
  position: relative;
  display: inline-block;
  overflow: hidden;
`;

const CatchCopy = styled.p<{ isIntersection: boolean; isTop?: boolean }>`
  font-size: 60px;
  line-height: 80px;
  word-break: keep-all;
  white-space: pre-wrap;
  opacity: 0;
  transform: translateY(100%);

  ${media.md} {
    font-size: 32px;
    line-height: 1.6;
  }

  ${media.smDown} {
    font-size: 24px;
    line-height: 1.6;
  }

  ${({ isTop }) =>
    isTop &&
    css`
      ${media.mdDown} {
        text-align: left;
      }
    `}

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      animation: ${commonKeyframes.fadeIn} 0.75s ${easings.easeOutCubic}
          var(--delay) forwards,
        ${commonKeyframes.slideIn} 0.75s ${easings.easeOutCubic} var(--delay)
          forwards;
    `};
`;

const CatchSub = styled.p<{ isIntersection: boolean; isTop?: boolean }>`
  font-size: 18px;
  line-height: 1.6;
  opacity: 0;
  --delay: 1.5s;

  ${media.md} {
    font-size: 16px;
  }

  ${media.smDown} {
    font-size: 12px;
  }

  ${({ isTop }) =>
    isTop &&
    css`
      ${media.mdDown} {
        text-align: left;
      }
    `}

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      animation: ${commonKeyframes.fadeIn} 0.75s ${easings.easeOutCubic}
        var(--delay) forwards;
    `}
`;

export default TitleWithCatchCopy;
